<template>
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card flat>
      <Async :loading="isFetched">
        <v-card-title class="flex-center">設定</v-card-title>
        <v-card-text class="flex-center">
          <v-container class="w80p">
            <v-form ref="form" @submit.prevent="submit">
              <v-text-field
                rounded
                class="mb-3"
                v-model="form.site_title"
                :rules="[$rules.required]"
                label="網站標題"
                outlined
              ></v-text-field>
              <v-text-field
                rounded
                class="mb-3"
                v-model="form.site_domain"
                :rules="[$rules.required]"
                label="網站網址"
                outlined
              ></v-text-field>
              <v-text-field
                rounded
                class="mb-3"
                v-model="form.cs_id"
                :rules="[$rules.required]"
                label="客服 ID"
                outlined
              ></v-text-field>

              <v-select
                rounded
                class="mb-3"
                v-model="form.day_cut_time"
                :rules="[$rules.required]"
                label="日切時間 UTC+8"
                outlined
                :items="['00:00', '12:00']"
              ></v-select>

              <v-combobox
                class="mb-3"
                v-model="form.whitelisted_login_ips"
                :rules="[$rules.required]"
                label="登入 IP 白名單"
                hint="登入 IP 白名單，可設定多組 IP，每組之間以“,”區隔"
                small-chips
                multiple
                persistent-hint
                rounded
                outlined
              ></v-combobox>
              <v-text-field
                rounded
                class="mb-3"
                v-model.number="form.max_failed_validates"
                :rules="[$rules.required]"
                type="number"
                label="驗證錯誤最大次數"
                hint="限制驗證錯誤最大次數，包括商戶登入、後台管理員登入、簡訊驗證碼、支付密碼與谷歌驗證碼"
                persistent-hint
                outlined
              ></v-text-field>
              <v-text-field
                rounded
                class="mb-3"
                v-model.number="form.block_cooldown_mins"
                :rules="[$rules.required]"
                type="number"
                label="凍結時間"
                hint="當錯誤次數超限時，此時間內系統拒絕再驗證，時間以『分鐘』為單位"
                persistent-hint
                outlined
              ></v-text-field>

              <v-switch
                class="mb-3"
                v-model="form.enabled_auto_reject_pay_order"
                label="自動駁回代付訂單"
                inset
              />

              <v-expand-transition>
                <v-text-field
                  v-if="form.enabled_auto_reject_pay_order"
                  rounded
                  class="mb-3"
                  v-model.number="form.auto_reject_pay_order_minute"
                  :rules="[$rules.required]"
                  type="number"
                  label="自動駁回時間 (分鐘)"
                  persistent-hint
                  outlined
                ></v-text-field>
              </v-expand-transition>

              <div class="d-flex">
                <v-switch
                  class="mb-3"
                  v-model="form.enabled_merchant_pay"
                  label="商戶代付"
                  inset
                ></v-switch>
                <SizeBox width="24" />
                <v-switch
                  class="mb-3"
                  v-model="form.enabled_pay_api"
                  label="API 代付"
                  inset
                ></v-switch>
              </div>
              <v-switch
                class="mb-3"
                v-model="form.enabled_otp"
                label="谷歌驗證"
                inset
              ></v-switch>
              <v-switch
                class="mb-3"
                v-model="form.enabled_collect_order_replenish_otp"
                label="補單谷歌驗證"
                inset
              ></v-switch>

              <v-select
                outlined
                rounded
                label="自動刷新時間"
                v-model="refreshTime"
                :items="refreshOptions"
              ></v-select>

              <v-text-field
                rounded
                class="mb-3"
                v-model="form.otp_code"
                :rules="[$rules.required]"
                label="谷歌驗證碼"
                outlined
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions class="flex-center">
          <v-btn
            rounded
            color="primary"
            depressed
            width="320"
            height="40"
            @click="submit"
            :loading="isLoading"
            :disabled="isCanEdit"
          >
            保存
          </v-btn>
        </v-card-actions>
      </Async>
      <SizeBox height="32" />
    </v-card>
  </div>
</template>

<script>
import { update } from '@/api/sysconfig';
import { mapGetters } from 'vuex';
import clone from 'just-clone';

export default {
  data() {
    return {
      isLoading: false,
      refreshTime: null,
      refreshOptions: [
        { text: '無', value: null },
        { text: '15 秒', value: 15 },
        { text: '30 秒', value: 30 },
        { text: '1 分鐘', value: 60 },
        { text: '3 分鐘', value: 180 }
      ],

      form: {
        site_title: '',
        site_domain: '',
        cs_id: '',
        block_multiple_login: false,
        whitelisted_login_ips: [],
        max_failed_validates: 0,
        block_cooldown_mins: 0,
        day_cut_time: '',
        enabled_auto_reject_pay_order: false,
        auto_reject_pay_order_minute: 0,
        enabled_merchant_pay: false,
        enabled_withdrawal_notify: false,
        enabled_otp: false,
        enabled_pay_api: false,
        enabled_collect_order_replenish_otp: false,

        otp_code: ''
      }
    };
  },

  computed: {
    ...mapGetters({
      isFetched: 'app/getSettingFetched',
      id: 'app/getSettingFileId',
      settings: 'app/getSiteSetting'
    }),
    isCanEdit() {
      return !this.$getGrantValue('platform_settings.edit');
    }
  },

  watch: {
    refreshTime(v) {
      if (v) {
        localStorage.setItem('art', v);
      } else {
        localStorage.removeItem('art');
      }
    }
  },

  created() {
    const autoRefreshTime = localStorage.getItem('art');
    this.refreshTime = autoRefreshTime ? Number(autoRefreshTime) : null;

    this.fetchData();
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch('app/FETCH_SITE_SETTINGS');
      this.form = this.$set(
        this,
        'form',
        clone({ ...this.form, ...this.settings })
      );
    },

    async submit() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;

      const { error } = await update({
        id: this.id,
        ...this.form
      });

      if (!error) {
        await this.fetchData();
        this.$toast('編輯成功');
      } else {
        const msg = (error.detail && error.detail.message) || '';
        this.$toast.error(`編輯失敗\n${msg}`);
      }

      this.form.otp_code = '';
      this.$refs.form.resetValidation();
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
